.p-checkbox .p-checkbox-box {
    border-color: #898888 !important; /* Teal-600 */
  }
  
.p-checkbox .p-checkbox-box.p-highlight {
    background-color: black !important; /* Teal-600 */
    border-color: #898888 !important;
  }
  


.p-inputswitch {
    transform: scale(0.75);
 }

 /* Change the background color of the switch when it's not checked */
.p-inputswitch-slider {
    background-color: rgb(255, 255, 255) !important; /* Black background */
    border-color: rgb(0, 0, 0) !important; /* Black border */
  }
  
  /* Change the handle color when the switch is not checked */
  .p-inputswitch-slider:before {
    background-color: rgb(0, 0, 0) !important; /* White handle */
  }
  
  /* When the switch is checked, change the background color */
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: rgb(0, 0, 0) !important; /* Black background when checked */
    border-color: rgb(0, 0, 0) !important; /* Black border when checked */
  }
  
  /* Change the handle color when the switch is checked */
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-color: rgb(255, 255, 255) !important; /* Black handle when checked */
  }




  
/* Change the selected button background to dark gray (blackish) */
.p-selectbutton .p-button.p-highlight {
    background-color: #464646 !important;  /* Dark gray background (blackish) */
    border-color: #464646 !important;      /* Dark gray border */
    color: white !important;               /* White text color for contrast */
}

/* Optionally, change the hover state for better user experience */
.p-selectbutton .p-button:hover {
    background-color: #d8d8d8 !important;  /* Light gray on hover */
    border-color: #d8d8d8 !important;      /* Light gray border on hover */
    color: white !important;               /* White text on hover */
}

/* Remove the focus effect */
.p-selectbutton .p-button:focus {
    outline: none !important;  /* Removes the default focus outline */
    box-shadow: none !important; /* Removes the default box-shadow on focus */
}

/* Apply rounded borders to the entire button group */
.p-selectbutton {
    border-radius: 1rem !important; /* Rounded corners for the entire button group */
    overflow: hidden; /* Prevents the inner buttons from breaking the rounded border */
}

/* Apply rounded borders to the entire button group container */
.p-selectbutton .p-button {
    border-radius: 0 !important; /* Remove border-radius from individual buttons */
    padding: 6px 12px !important; /* Reduce padding for smaller buttons */
    font-size: 14px !important;   /* Smaller font size */
}

/* Apply rounded borders to the first button (left side) */
.p-selectbutton .p-button:first-child {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

/* Apply rounded borders to the last button (right side) */
.p-selectbutton .p-button:last-child {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}



/* Custom dropdown container */
.custom-dropdown .p-dropdown {
  font-size: 10px !important; /* Reduce font size */
  padding: 2px 4px !important; /* Compact padding */
  height: 25px !important; /* Reduce height */
  color: black !important; /* Set text color */
}

/* Dropdown label */
.custom-dropdown .p-dropdown-label {
  font-size: 16px !important; /* Smaller label text */
  padding: 4px 6px !important; /* Adjust padding */
  color: black !important;
}

/* Dropdown trigger icon */
.custom-dropdown .p-dropdown-trigger {
  font-size: 12px !important;
  color: black !important;
}

/* Dropdown panel (list container) */
.custom-dropdown .p-dropdown-panel {
  border-radius: 6px !important;
  overflow-y: auto !important; /* Enable scrolling */
  max-height: 300px !important; /* Increase max height for longer list */
}

/* Wrapper for items */
.custom-dropdown .p-dropdown-items-wrapper {
  padding: 0px !important; /* Reduce padding */
}

.custom-dropdown .p-dropdown-item {
  font-size: 30px !important;
  padding: 2px 4px !important;
  height: 18px !important;
  line-height: 1 !important;
  max-height: 18px !important; /* Ensure minimum height */
}

.custom-dropdown .p-dropdown-items {
  padding: 0px !important;
  font-size: 10px !important;
  line-height: 1 !important;
}


/* Optional: Adjust the dropdown open state */
.custom-dropdown .p-dropdown-open {
  min-height: 500px !important; /* Ensure more options are visible */
}



  /* Make tooltip background white and text black */
.custom-tooltip .p-tooltip {
    background-color: white !important;
    color: black !important;
    border: 1px solid #ccc !important; /* Light gray border */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important; /* Optional shadow */
}

/* Style the arrow of the tooltip */
.custom-tooltip .p-tooltip-arrow {
    border-top-color: white !important; /* Adjust arrow color to match background */
}





/* Custom class for the slider */
.custom-slider .p-slider .p-slider-track {
    height: 12px !important; /* Thicker track */
    background-color: #2c2c2c !important; /* Green color for track */
  }
  
  .custom-slider .p-slider .p-slider-handle {
    height: 24px !important; /* Larger handle */
    width: 24px !important;  /* Larger handle */
    border-color: #4caf50 !important; /* Handle border color matching track */
    background-color: #fff !important; /* White handle background */
  }
  
  .custom-slider .p-slider .p-slider-rail {
    height: 12px !important; /* Adjust the rail (background) of the slider */
    background-color: #ccc !important; /* Light gray color */
  }
  
  /* Optional: Remove dots from the range slider */
  .custom-slider .p-slider .p-slider-range {
    display: none !important;
  }
  


.horizontal-accordion .p-accordion {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.horizontal-accordion .p-accordion-tab {
    flex: 1;
    min-width: 120px; /* Adjust width as needed */
}

.horizontal-accordion .p-accordion-header {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-align: center;
}
